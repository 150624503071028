import React from 'react'
import Helmet from 'react-helmet'
import config, { favicon } from '../utils/config'

const Seo = ({ description = '', language = 'en', meta = [], title }) => {
  const { showLegacyBanner, meta: siteMeta } = config

  const metaDescription = description || siteMeta?.[language]?.description
  const metaTitle = title || siteMeta?.[language]?.title

  return (
    <Helmet
      htmlAttributes={{
        language,
      }}
      title={metaTitle}
      titleTemplate="%s"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: siteMeta?.[language]?.title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        showLegacyBanner && {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ]
        .filter(Boolean)
        .concat(meta)}
    >
      <link
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="//cdn.jsdelivr.net/npm/katex@latest/dist/katex.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link rel="shortcut icon" href={favicon?.default} type="image/x-icon" />
    </Helmet>
  )
}

export default React.memo(Seo)
